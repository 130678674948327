<template>
  <div class="address-wrapper">
    <elements-inputs-text ref="searchTextFieldRef" v-model="searchKeywords" :placeholder="placeholder" :label="label" type="text" variant="underlined" @keydown.prevent.enter="handleKeydownEvent" />
  </div>
</template>

<script lang="ts" setup>
import {nextTick, PropType, Ref} from 'vue';
import regionList from '~/composables/nzRegions';

// EMIT Definitions
const emit = defineEmits<{
  (event: 'update:modelValue', payload: string | null): void;
}>();

const props = defineProps({
  modelValue: {
    type: Object as PropType<string>,
    default: null,
  },
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Region, city or suburb...',
  },
});

const hasGoogleInitiated: Ref<boolean> = ref(false);
const searchTextFieldRef: Ref<HTMLElement | null> = ref(null);
const inputFieldElement: Ref<HTMLElement | null> = ref(null);
const searchKeywords: Ref<string | null> = ref(null);
const boundExceedBy: Ref<number> = ref(10);  // this is in kilometers
const autocomplete: Ref<any> = ref(null);
const isEventHandlerRunning: Ref<boolean> = ref(false);

/**
 * Component MOUNTED!
 */
onMounted(() => {
  setHasGoogleInitiated();
});

/**
 * Set hasGoogleInitiated variable to display the field
 *
 * @private
 */
function setHasGoogleInitiated () {
  if (window && (window as any).google) {
    hasGoogleInitiated.value = true;
    nextTick(() => {
      initField();
    });
  } else {
    setTimeout(() => {
      setHasGoogleInitiated();
    }, 500);
  }
}

function initField () {
  if (searchTextFieldRef.value) {
    inputFieldElement.value = (searchTextFieldRef.value as any).$el.querySelector('input');
    autocomplete.value = new (window as any).google.maps.places.Autocomplete(
        (inputFieldElement.value), {
          types: ['(regions)'],
          componentRestrictions: { country: 'nz' },
          fields: ['formatted_address', 'geometry', 'place_id', 'types'],
        },
    );

    autocomplete.value.addListener('place_changed', () => {
      const place = autocomplete.value.getPlace();
      getCityBounds(place);
    });
  }
}

/**
 * Get city bounds data
 */
function getCityBounds (placeData: any) {
  if (!placeData || !placeData.formatted_address) {
    return;
  }
  let boundsString: string;
  const formattedAddress = placeData.formatted_address;

  // Check if the selected place matches a predefined region
  const matchedRegion = regionList.find(region => region.searchLabels.includes(placeData.formatted_address.split(',')[0]));

  if (matchedRegion) {
    // If matched, use the predefined map bounds
    boundsString = `(${matchedRegion.mapBounds})`;
  } else {
    const modifiedExceedBy = placeData.types.includes('administrative_area_level_1') ? boundExceedBy.value * 2 : boundExceedBy.value / 2;
    const location = placeData.geometry.location;

    // Get new bounds based on the exceed value
    const north = location.lat() + modifiedExceedBy / 111.32;
    const south = location.lat() - modifiedExceedBy / 111.32;
    const east = location.lng() + modifiedExceedBy / (111.32 * Math.cos((location.lat() * Math.PI) / 180));
    const west = location.lng() - modifiedExceedBy / (111.32 * Math.cos((location.lat() * Math.PI) / 180));

    boundsString = `(${south}, ${east}, ${south}, ${west}, ${north}, ${west}, ${north}, ${east})`;
  }

  searchKeywords.value = formattedAddress;
  emit('update:modelValue', boundsString);
}

/**
 * Auto select the first suggestion when pressing enter
 */
function handleKeydownEvent() {
  if (isEventHandlerRunning.value) {
    return;
  }
  if (autocomplete.value && document.querySelector('.pac-item')) {
    setTimeout(() => {
      const pacInput = inputFieldElement.value as HTMLInputElement;  // Assuming this is your reference to the autocomplete input field
      if (pacInput) {
        // Simulate Down Arrow key press
        const arrowDownEvent = new KeyboardEvent('keydown', {
          key: 'ArrowDown',
          code: 'ArrowDown',
          keyCode: 40,
          bubbles: true,
          cancelable: true,
        });
        pacInput.dispatchEvent(arrowDownEvent);

        // After 10 milliseconds, simulate Enter key press
        setTimeout(() => {
          const enterKeyEvent = new KeyboardEvent('keydown', {
            key: 'Enter',
            code: 'Enter',
            keyCode: 13,
            bubbles: true,
            cancelable: true,
          });
          pacInput.dispatchEvent(enterKeyEvent);
          pacInput.blur();
          nextTick(() => {
            isEventHandlerRunning.value = false;
          });
        }, 10);
      }
    }, 10);
  }
}
</script>

<style lang="scss">
.pac-container {
  z-index: 9999;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  &:after {
    display: none;
  }

  .pac-item {
    border-top: unset !important;
    padding: 2px 4px;
    cursor: pointer;
  }
}

.address-wrapper.__input-search {
  .__input-field-wrapper {
    border-bottom: unset !important;

    &:after {
      display: none !important;
    }

    input {
      ///
    }
  }
}
</style>
